<template>
	<div>
        <CompleteTicket v-if="show_complete_ticket" :item="action_item" @close='close_modals' @success='completed_action'/>
        <CancelTicket v-if="show_cancel_ticket" :item="action_item" @close='close_modals' @success='completed_action'/>
        <PendingTicket v-if="show_revert_ticket" :item="action_item" @close='close_modals' @success='completed_action'/>


        <div class="container-fluid">
            <div class="card mt-3">

                <div class="card-body">
                    <h4 class="card-title">Shipping Tickets</h4>
                    <div class="actions">
                    <a class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Item'" href="/ticket/add"><i class="zwicon-plus"></i> Add Shipping Ticket</a>
                                    
                    </div>
                    
                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <div class="dataTables__top">
                                <div id="data-table_filter" class="dataTables_filter">
                                    <label>Search:<input type="search" v-model="searchterm" v-on:keyup.enter='search' class="" placeholder="Search tickets by Type, Date, To, or From" aria-controls="data-table"></label>
                                </div>
                            </div>
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Date</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Time</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Type</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">To</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">PO #</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">From</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">WO #</th>
                                    <th class="sorting text-center" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Item Count</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">State</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in ticket_list.list" v-bind:key="item" :class="{'cancelled':item.state == 'Cancelled'}">
                                <td>
                                    {{item.date || ''}}
                                </td>
                                <td>
                                    {{item.time || ''}}
                                </td>
                                <td>
                                    {{item.type || ''}}
                                </td>
                                <td>
                                    {{item.to || ''}}
                                    
                                </td>
                                <td>
                                    {{item.PO || ''}}
                                </td>
                                <td>
                                    {{item.from || ''}}
                                </td>
                                <td>
                                    {{item.WO || ''}}
                                </td>
                                <td class="text-center">
                                    {{item.count || ''}}
                                </td>
                                <td>
                                    <span class="text-success" v-if="item.state == 'completed'">Completed</span>
                                    <span class="text-muted" v-if="item.state == 'cancelled'">Cancelled</span>
                                    <span v-if="item.state == 'pending'">Pending</span>
                                </td>
                                
                                <td style='text-align: right; position: relative;' v-if='item.ticket_id > 0' @click='toggle_v_dropdown(item.ticket_id)'>
                                    <button class="btn btn-primary btn-sm" v-tooltip="'Toggle User Menu'"><i class="zwicon-more-h"></i>&nbsp;Actions</button>
                                    <div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == item.ticket_id.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
                                        <a class="dropdown-item" :href="'/ticket/view/'+item.ticket_id">View</a>
                                        <a v-if="item.state == 'pending'" class="dropdown-item" :href="'/ticket/edit/'+item.ticket_id">Edit</a>
                                        <a v-if="item.state == 'pending'" class="dropdown-item" @click='mark_complete(item)'>Mark as Complete</a>
                                        <a v-if="item.state == 'pending'" class="dropdown-item text-danger" @click='cancel_item(item)'>Cancel</a>
                                        <a v-if="item.state != 'pending'" class="dropdown-item text-danger" @click='revert_item(item)'>Revert to Pending</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                            
                            <div class='row mt-2' v-if='ticket_list.pagination !== undefined'>
                                <div class='col-md-6 pt-2'>Showing {{(ticket_list.pagination.per_page*ticket_list.pagination.page)+1}} to {{pagination_max}} of {{ticket_list.pagination.total_items}} items.</div>
                                <div class='col-md-6'>
                                    <div class="ml-auto" v-if='pages.length > 1'>
                                        <Pagination @set_page="view_page" :current_page='ticket_list.pagination.page' :pages='pages' />
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import { LOAD_TICKET_LIST } from "@/store/actions.type";

    import CompleteTicket from '@/views/Ticket/Modals/CompleteTicket';
    import PendingTicket from '@/views/Ticket/Modals/PendingTicket';
    import CancelTicket from '@/views/Ticket/Modals/CancelTicket';
    //import AddItem from '@/views/Inventory/Modals/AddItem';
    //import EditItem from '@/views/Inventory/Modals/EditItem';

	import Pagination from '@/components/General/Pagination';

	export default {
		name: 'Ticket',
		components: {
            Pagination,
            CompleteTicket,
            PendingTicket,
            CancelTicket
		},
		props: {
			error: {
				type: String,
				default: "" 
			},
			success: {
				type: String,
				default: ""
			}
		},
        data(){
            return {
                filter_list: false,
                active_item: '',
                show_filter_modal: false,
                show_delete_ticket: false,
                show_revert_ticket: false,
                show_cancel_ticket: false,
                show_complete_ticket: false,
                success_msg: '',
				filter_error: '',
                filter: {
                    search: ''
                },

                searchterm: ''
            }
        },
        beforeMount() {
            this.reset_filter_form();
			this.load_list(function(){
                console.log('try to load')
			}, function(){
                console.log('load error')
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load ticket list." }});
			});
		},
		computed: {
            pagination_max(){
				var page_max = (this.ticket_list.pagination.per_page*(this.ticket_list.pagination.page+1));
				if(page_max > this.ticket_list.pagination.total_items)  page_max = this.ticket_list.pagination.total_items;
				return page_max;
			},
			pages(){
				let page_list = [];
				for(var i = 0;i < Math.ceil(this.ticket_list.pagination.total_items / this.ticket_list.pagination.per_page);i++){
					page_list.push((i*1)+1);
				}
				return page_list;
			},
			...mapGetters(['ticket_list', 'user', 'loading'])
		},
        methods: {
            toggle_v_dropdown(id){
                if(this.active_item == id) this.active_item = '';
                else this.active_item = id;
            },
            reset_filter_form(){
				this.filter = {
					page: 0,
					search: '',
					sort_by: 'id',
					sort_dir: 'DESC'
				};
			},
			open_filters(){
				this.show_filter_modal = true;
			},
			close_filters(){
				this.show_filter_modal = false;
			},
            load_list(success_fn, failure_fn){
				this.list_loading = true;
				this.filter_error = "";
				console.log('load list')
                this.filter.search = this.searchterm;
				store.dispatch(LOAD_TICKET_LIST, this.filter).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            view_page(page){
				this.filter.page = page;
				this.load_list(this.close_filters, function(){
					this.success_msg = '';
					this.filter_error = '';
				});
			},
            cancel_item(item){
				this.action_item = item;
				this.show_cancel_ticket = true;
			},
            mark_complete(item){
                this.action_item = item;
                console.log('here')
				this.show_complete_ticket = true;
            },
            revert_item(item){
				this.action_item = item;
				this.show_revert_ticket = true;
			},
            completed_action(){
                this.action_item = {};
                this.close_modals();
                this.load_list(() => {}, () => {});
            },
            close_modals(){
				this.show_revert_ticket = false;
				this.show_complete_ticket = false;
				this.show_cancel_ticket = false;
            },
            search(){
                this.load_list(() => {}, ()=>{});
            },
            clear_search(){
                this.filter.search = '';
                this.search();
            }
        },
        watch: {
            searchterm(newdata){
                if(newdata != '') return;
                this.search();
            }
        }
	}
</script>