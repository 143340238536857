<template>
	<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class='modal-content' v-if='action_running'>
				<div class="modal-body">
					<h5 class='text-info'>Updating Ticket</h5>
					Hold on tight. We are updating ticket status.
				</div>
			</div>
			<div class="modal-content" v-if='!action_running'>
				<div class="modal-header">
					<h5 class="modal-title">Revert to Pending</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
				</div>
				<div class="modal-body">
					<h5 class='text-danger'>Are you sure you want to revert this ticket status?</h5>
					By reverting this ticket:
					<ul>
						<li>Shipping ticket will be editable once again.</li>
						<li>Items in ticket will have their statuses updated.</li>
					</ul>
				</div>
				<ErrorMessage :message='error' v-if='error.length > 0' />
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" @click='action'>Revert to Pending</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import store from "@/store";

	import PermissionService from "@/common/permission.service";

	import { PENDING_TICKET } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'PendingTicket',
		components: {
			ErrorMessage
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		data(){
			return {
				error: '',
				action_running: false
			};
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			close(){
				if(this.action_running) return;
				this.$emit('close');
			},
			action(){
				if(this.action_running) return;
				this.action_running = true;
				store.dispatch(PENDING_TICKET, { "shipping_ticket_id": this.item.ticket_id}).then(() => {
					this.$emit('success');
				}).catch(message => {
					this.error = message;
					this.action_running = false;
				}).finally(() => {
				});
			}
		}
	}
</script>